import Layout2 from "@/layout/Layout2";

export default [
  {
    path: "/views/",
    component: Layout2,
    children: [
      {
        path: "landing",
        name: "Landing",
        component: () => import("@/views/Landing.vue")
      }
    ]
  }
];
