<template>
  <!-- COMPONENTE MENU LATERAL  -->
  <!-- Especificamos las rutas en las que NO queremos que se muestre el menú -->
  <sidenav
    v-if="
      $route.path !== '/views/Landing' &&
        $route.path !== '/views/pedidos/detalle-pedidos' &&
        $route.path !== '/views/ventas/ventas-cliente' &&
        $route.path !== '/views/ventas/facturacion-cliente' &&
        $route.path !== '/views/ventas/imprimir-factura' &&
        $route.path !== '/views/ventas/tarifa-cliente' &&
        $route.path !== '/views/ventas/llamadas-cliente' &&
        $route.path !== '/views/ventas/detalle-comerciales' &&
        $route.path !== '/views/inicio/estadisticas2' &&
        $route.path !== '/views/proveedores/detalle-pedidos-proveedores' &&
        $route.path !== '/views/stock/descarga-pedidos' &&
        $route.path !== '/views/marcasymodelos/detalle-marcas' &&
        $route.path !== '/views/marcasymodelos/detalle-modelos' &&
        $route.path !==
          '/views/proveedores/imprimir-detalle-pedido-proveedor' &&
        $route.path !== '/views/pedidos/imprimir-detalle-pedido-cliente' &&
        $route.path !== '/views/formularioContacto'
    "
    :orientation="orientation"
    :class="curClasses"
  >
    <!-- Imágenes del logo de la empresa con el menú desplegado o colapsado -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <img
        v-if="collapsed !== true"
        src="/img/silicon_logos/logoSVVApaisadoBlanco.svg"
        weight="23"
        height="23"
      /><br />
      <img
        v-if="collapsed === true"
        src="/img/silicon_logos/imagotipoSVV.svg"
        style="margin-left:1rem;"
        weight="25"
        height="25"
      /><br />

      <a
        href="javascript:void(0)"
        class="layout-sidenav-toggle sidenav-link text-large ml-auto"
        @click="toggleSidenav()"
      >
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Links -->
    <div
      class="sidenav-inner"
      :class="{ 'py-1': orientation !== 'horizontal' }"
    >
    <!-- Link a página de landing -->
      <!-- <sidenav-router-link
        to="/views/Landing"
        :exact="true"
        icon="fa-solid fa-desktop"
        >Inicio</sidenav-router-link
      > -->
      <!-- Menu -->
      <sidenav-menu
        icon="fab fa-jedi-order"
        :active="isMenuActive('/views/menu')"
        :open="isMenuOpen('/views/menu')"
      >
        <template slot="link-text">Menú</template>
        <sidenav-router-link to="/views/inicio/estadisticas" :exact="true"
          >Estadísticas</sidenav-router-link
        >
        <sidenav-router-link to="/views/inicio/login" :exact="true"
          >Login</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-divider class="mb-1" />

      <!-- Marcas y modelos -->
      <sidenav-menu
        icon="fab fa-monero"
        :active="isMenuActive('/views/marcasymodelos')"
        :open="isMenuOpen('/views/marcasymodelos')"
      >
        <template slot="link-text">Marcas y Modelos</template>

        <sidenav-router-link
          icon="fal fa-drone"
          to="/views/marcasymodelos/cuadro-marcas"
          :exact="true"
          >Cuadro de marcas</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-computer-classic"
          to="/views/marcasymodelos/cuadro-modelos"
          :exact="true"
          >Cuadro de modelos</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-divider class="mb-1" />

      <!-- Ventas -->
      <sidenav-menu
        icon="fab fa-creative-commons"
        :active="isMenuActive('/views/ventas')"
        :open="isMenuOpen('/views/ventas')"
      >
        <template slot="link-text">Control Comercial</template>

        <sidenav-router-link
          icon="fad fa-chart-line"
          to="/views/ventas/seguimiento-ventas"
          :exact="true"
          >Seguimiento ventas</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-file-spreadsheet"
          to="/views/ventas/seguimiento-pedidos"
          :exact="true"
          >Pedidos de clientes</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-street-view"
          to="/views/ventas/informe-clientes"
          :exact="true"
          >Informe clientes</sidenav-router-link
        >
        <sidenav-router-link
          icon="fad fa-pizza"
          to="/views/ventas/cuota-mercado"
          :exact="true"
          >Cuota de mercado</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-sign-out"
          to="/views/ventas/clientes-fuera-plazo"
          :exact="true"
          >Clientes fuera plazo</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-money-check-edit-alt"
          to="/views/ventas/ranking-clientes"
          :exact="true"
          >Ranking clientes</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-address-book"
          to="/views/ventas/direccion-por-objetivos"
          :exact="true"
          >D.P.O.</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-male"
          to="/views/ventas/Comerciales"
          :exact="true"
          >Comerciales</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-map-marker-alt"
          to="/views/ventas/mapa-ventas"
          :exact="true"
          >Mapa ventas</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-divider class="mb-1" />

      <!-- Administración -->
      <sidenav-menu
        icon="fal fa-ad"
        :active="isMenuActive('/views/administracion')"
        :open="isMenuOpen('/views/administracion')"
      >
        <template slot="link-text">Administración</template>

        <sidenav-router-link
          icon="fal fa-sack-dollar"
          to="/views/administracion/tesoreria"
          :exact="true"
          >Tesorería</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-file-invoice"
          to="/views/administracion/facturas-proveedores"
          :exact="true"
          >Facturas proveedor</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-file-invoice-dollar"
          to="/views/administracion/facturas-clientes"
          :exact="true"
          >Facturas clientes</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-divider class="mb-1" />

      <sidenav-menu
        icon="fab fa-product-hunt"
        :active="isMenuActive('/views/proveedores')"
        :open="isMenuOpen('/views/proveedores')"
      >
        <template slot="link-text">Proveedores</template>

        <sidenav-router-link
          icon="fal fa-arrow-circle-right"
          to="/views/proveedores/pedidos-realizados"
          :exact="true"
          >Pedidos realizados</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-file-upload"
          to="/views/proveedores/subir-archivos"
          :exact="true"
          >Subir archivos</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-divider class="mb-1" />

      <sidenav-menu
        icon="fal fa-box-alt"
        :active="isMenuActive('/views/stock')"
        :open="isMenuOpen('/views/stock')"
      >
        <template slot="link-text">Almacén</template>
        <sidenav-router-link
          icon="fal fa-dolly"
          to="/views/stock/recepcion-pedidos"
          :exact="true"
          >Recepción pedidos</sidenav-router-link
        >
        <sidenav-router-link
          icon="fal fa-inventory"
          to="/views/stock/inventario"
          :exact="true"
          >Inventario</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-divider class="mb-1" />
      <sidenav-menu
        icon="fad fa-frosty-head"
        :active="isMenuActive('/views/direccion')"
        :open="isMenuOpen('/views/direccion')"
      >
        <template slot="link-text">Dirección</template>
        <!-- <sidenav-header class="small font-weight-semibold"
          >MANUAL DE USUARIO</sidenav-header
        > -->
        <sidenav-router-link
          icon="fal fa-skull-crossbones"
          to="/views/direccion/control-direccion"
          :exact="true"
          >Control</sidenav-router-link
        >
        <sidenav-router-link
          icon="fab fa-dailymotion"
          to="/views/direccion/seguimiento-diario"
          :exact="true"
          >Seguimiento diario</sidenav-router-link
        >
        <!--  <sidenav-router-link
          icon="fab fa-medium-m"
          to="/views/direccion/seguimiento-mensual"
          :exact="true"
          >Seguimiento mes</sidenav-router-link
        > -->
      </sidenav-menu>
      <sidenav-divider class="mb-1" />
      <sidenav-menu
        icon="fab fa-readme"
        :active="isMenuActive('/views/manualusuario')"
        :open="isMenuOpen('/views/manualusuario')"
      >
        <template slot="link-text">Manual</template>

        <sidenav-router-link
          icon="fab fa-mandalorian"
          to="/views/manual/estructura"
          :exact="true"
          >Manual</sidenav-router-link
        >
      </sidenav-menu>

      <sidenav-divider class="mb-1" />
    </div>
    <div class="rodapro_info">
      <a href="https://www.rodapro.es" target="_blank">
        <img
          src="/img/silicon_logos/logoSVVBlanco.svg"
          weight="70"
          height="70"
          class="mb-1"
        />
      </a>
      <div>
        <i class="fas fa-envelope"></i>&nbsp;<a
          href="mailto:info@siliconvalleyvigo.com"
          target="_blank"
          style="text-decoration:none; color:white;"
          >info@siliconvalleyvigo.com
        </a>
      </div>
      <!-- <div><i class="fas fa-phone"></i>&nbsp;+34 634 73 07 09</div> -->
    </div>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider

    /* eslint-enable vue/no-unused-components */
  },
  data() {
    return {
      collapsed: false
    };
  },
  props: {
    orientation: {
      type: String,
      default: "vertical"
    }
  },

  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return `bg-${bg} ${
        this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0"
      }`;
    }
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },
    // collapsed cambia de false a true y vice versa cada vez que se hace click en el botón hamguesa y con el cambia la imagen
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
      this.collapsed = !this.collapsed;
    }
  }
};
</script>
