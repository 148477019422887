<template>
  <div
    v-if="
      $route.path !== '/views/Landing' &&
        $route.path !== '/views/pedidos/detalle-pedidos' &&
        $route.path !== '/views/ventas/facturacion-cliente' &&
        $route.path !== '/views/ventas/ventas-cliente' &&
        $route.path !== '/views/ventas/tarifa-cliente' &&
        $route.path !== '/views/inicio/estadisticas2' &&
        $route.path !== '/views/formularioContacto'
    "
  >
    <b-navbar
      :variant="getLayoutNavbarBg()"
      class="align-items-lg-center container-p-x"
    >
      <!-- Brand demo (see demo.css) -->
      <!--   <b-navbar-brand to="/" class="app-brand demo d-lg-none py-0 mr-4">
        <span class="app-brand-text demo font-weight-normal ml-2">
          <img src="/img/rodamoto/logoRodamoto.svg" weight="35" height="35"
        /></span>
      </b-navbar-brand> -->

      <!-- Sidenav toggle (see demo.css) -->
      <b-navbar-nav
        class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto"
        v-if="sidenavToggle"
      >
        <a
          class="nav-item nav-link px-0 mr-lg-4"
          href="javascript:void(0)"
          @click="toggleSidenav"
        >
          <i class="ion ion-md-menu text-large align-middle" />
        </a>
      </b-navbar-nav>

      <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

      <b-collapse
        is-nav
        id="app-layout-navbar"
        v-if="
          $route.path !== '/views/inicio/estadisticas' &&
            $route.path !== '/views/inicio/login'
        "
      >
        <!-- Divider -->
        <hr class="d-lg-none w-100 my-2" />

        <b-navbar-nav class="align-items-lg-center">
          <!-- Search -->
          <label class="nav-item navbar-text navbar-search-box p-0 active">
            <i class="ion ion-ios-search navbar-icon align-middle"></i>
            <span class="navbar-search-input pl-2">
              <input
                type="text"
                class="form-control navbar-text mx-2"
                placeholder="Buscar..."
                style="width:200px"
              />
            </span>
          </label>
        </b-navbar-nav>

        <b-navbar-nav class="align-items-lg-center ml-auto">
          <b-nav-item-dropdown
            no-caret
            :right="!isRtlMode"
            class="demo-navbar-notifications mr-lg-3"
          >
            <template slot="button-content">
              <i
                class="ion ion-md-notifications-outline navbar-icon align-middle"
              ></i>
              <span class="badge badge-primary badge-dot indicator"></span>
              <span class="d-lg-none align-middle">&nbsp; Notificaciones</span>
            </template>

            <div class="bg-primary text-center text-white font-weight-bold p-3">
              4 Nuevas Notificaciones
            </div>
            <b-list-group flush>
              <b-list-group-item
                href="javascript:void(0)"
                class="media d-flex align-items-center"
              >
                <div
                  class="ui-icon ui-icon-sm ion ion-md-home bg-secondary border-0 text-white"
                ></div>
                <div class="media-body line-height-condenced ml-3">
                  <div class="text-body">Conectado desde 192.168.1.1</div>
                  <div class="text-light small mt-1">
                    Aliquam ex eros, imperdiet vulputate hendrerit et.
                  </div>
                  <div class="text-light small mt-1">Hace 2h</div>
                </div>
              </b-list-group-item>

              <b-list-group-item
                href="javascript:void(0)"
                class="media d-flex align-items-center"
              >
                <div
                  class="ui-icon ui-icon-sm ion ion-md-person-add bg-info border-0 text-white"
                ></div>
                <div class="media-body line-height-condenced ml-3">
                  <div class="text-body">
                    Tienes <strong>4</strong> mensajes nuevos
                  </div>
                  <div class="text-light small mt-1">
                    Phasellus nunc nisl, posuere cursus pretium nec, dictum
                    vehicula tellus.
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item
                href="javascript:void(0)"
                class="media d-flex align-items-center"
              >
                <div
                  class="ui-icon ui-icon-sm ion ion-md-power bg-danger border-0 text-white"
                ></div>
                <div class="media-body line-height-condenced ml-3">
                  <div class="text-body">Servidor actualizado</div>
                  <div class="text-light small mt-1">
                    Hace 19h
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item
                href="javascript:void(0)"
                class="media d-flex align-items-center"
              >
                <div
                  class="ui-icon ui-icon-sm ion ion-md-warning bg-warning border-0 text-body"
                ></div>
                <div class="media-body line-height-condenced ml-3">
                  <div class="text-body">79% Tareas completadas</div>
                  <div class="text-light small mt-1">
                    Etiam nec fringilla magna. Donec mi metus.
                  </div>
                  <div class="text-light small mt-1">
                    Hace 1h
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>

            <a
              href="javascript:void(0)"
              class="d-block text-center text-light small p-2 my-1"
              >Mostrar todas las notificaciones</a
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            no-caret
            :right="!isRtlMode"
            class="demo-navbar-messages mr-lg-3"
          >
            <template slot="button-content">
              <i class="ion ion-ios-mail navbar-icon align-middle"></i>
              <span class="badge badge-primary badge-dot indicator"></span>
              <span class="d-lg-none align-middle">&nbsp; Mensajes</span>
            </template>

            <div class="bg-primary text-center text-white font-weight-bold p-3">
              4 Nuevos Mensajes
            </div>
            <b-list-group flush>
              <b-list-group-item
                href="javascript:void(0)"
                class="media d-flex align-items-center"
              >
                <img
                  :src="`${publicUrl}img/avatars/6-small.png`"
                  class="d-block ui-w-40 rounded-circle"
                  alt
                />
                <div class="media-body ml-3">
                  <div class="text-body line-height-condenced">
                    Sit meis deleniti eu, pri vidit meliore docendi ut.
                  </div>
                  <div class="text-light small mt-1">
                    Maria Gutiérrez &nbsp;·&nbsp; Hace 58m
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item
                href="javascript:void(0)"
                class="media d-flex align-items-center"
              >
                <img
                  :src="`${publicUrl}img/avatars/4-small.png`"
                  class="d-block ui-w-40 rounded-circle"
                  alt
                />
                <div class="media-body ml-3">
                  <div class="text-body line-height-condenced">
                    Mea et legere fuisset, ius amet purto luptatum te.
                  </div>
                  <div class="text-light small mt-1">
                    Pedro Díaz &nbsp;·&nbsp; Hace 1h
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item
                href="javascript:void(0)"
                class="media d-flex align-items-center"
              >
                <img
                  :src="`${publicUrl}img/avatars/5-small.png`"
                  class="d-block ui-w-40 rounded-circle"
                  alt
                />
                <div class="media-body ml-3">
                  <div class="text-body line-height-condenced">
                    Sit meis deleniti eu, pri vidit meliore docendi ut.
                  </div>
                  <div class="text-light small mt-1">
                    Noelia Alvarez &nbsp;·&nbsp; Hace 2h
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item
                href="javascript:void(0)"
                class="media d-flex align-items-center"
              >
                <img
                  :src="`${publicUrl}img/avatars/11-small.png`"
                  class="d-block ui-w-40 rounded-circle"
                  alt
                />
                <div class="media-body ml-3">
                  <div class="text-body line-height-condenced">
                    Lorem ipsum dolor sit amet, vis erat denique in, dicunt
                    prodesset te vix.
                  </div>
                  <div class="text-light small mt-1">
                    Beatriz Suárez &nbsp;·&nbsp; Hace 5h
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>

            <a
              href="javascript:void(0)"
              class="d-block text-center text-light small p-2 my-1"
              >Mostrar todos los mensajes</a
            >
          </b-nav-item-dropdown>

          <!-- Divider -->
          <div
            class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"
          >
            |
          </div>

          <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user">
            <template slot="button-content">
              <span
                class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
              >
                <img
                  :src="`${publicUrl}img/avatars/1.png`"
                  alt
                  class="d-block ui-w-30 rounded-circle"
                />
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">Paula Rodríguez</span>
              </span>
            </template>

            <b-dd-item
              ><i class="ion ion-ios-person text-lightest"></i> &nbsp; Mi
              cuenta</b-dd-item
            >
            <b-dd-item
              ><i class="ion ion-ios-mail text-lightest"></i> &nbsp;
              Mensajes</b-dd-item
            >
            <b-dd-item
              ><i class="ion ion-md-settings text-lightest"></i> &nbsp;
              Ajustes</b-dd-item
            >
            <b-dd-divider />
            <b-dd-item
              ><i class="ion ion-ios-log-out text-danger"></i> &nbsp;
              Desconectarse</b-dd-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "app-layout-navbar",

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    }
  }
};
</script>
