var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$route.path !== '/views/Landing' &&
      _vm.$route.path !== '/menu/login' &&
      _vm.$route.path !== '/views/ventas/imprimir-factura' &&
      _vm.$route.path !==
        '/views/proveedores/imprimir-detalle-pedido-proveedor' &&
      _vm.$route.path !== '/views/pedidos/imprimir-detalle-pedido-cliente'
  )?_c('div',[_c('nav',{staticClass:"layout-footer footer",class:_vm.getLayoutFooterBg()},[_vm._m(0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3"},[_c('div',{staticClass:"pt-1 mt-3",staticStyle:{"font-size":"0.6rem"}},[_vm._v("\n        Diseñado por\n        "),_c('a',{attrs:{"href":"https://www.siliconvalleyvigo.com","target":"_blank"}},[_c('img',{staticClass:"ml-1 mr-1 mb-1",attrs:{"src":"/img/silicon_logos/logoSVVBlanco_1.svg","weight":"25","height":"25"}})]),_vm._v("\n        © 2022\n      ")]),_vm._v(" "),_c('div',[_c('a',{staticClass:"footer-link pt-3",attrs:{"href":"https://www.siliconvalleyvigo.com","target":"_blank"}},[_vm._v("Sobre nosotros")]),_vm._v(" "),_c('a',{staticClass:"footer-link pt-3 ml-4",attrs:{"href":"#","target":"_blank"}},[_vm._v("Política de privacidad")]),_vm._v(" "),_c('a',{staticClass:"footer-link pt-3 ml-4",attrs:{"href":"#","target":"_blank"}},[_vm._v("Política de cookies")]),_vm._v(" "),_c('a',{staticClass:"footer-link pt-3 ml-4",attrs:{"href":"#","target":"_blank"}},[_vm._v("Contacto")])])])}]

export { render, staticRenderFns }