import Layout2 from "@/layout/Layout2";

export default [
  {
    path: "/views/administracion",
    component: Layout2,
    children: [
      {
        path: "tesoreria",
        name: "tesoreria",
        component: () => import("@/views/Administracion/Tesoreria")
      }
    ]
  },
  {
    path: "/views/administracion",
    component: Layout2,
    children: [
      {
        path: "facturas-proveedores",
        name: "Facturas de proveedores",
        component: () => import("@/views/Administracion/FacturasProveedores")
      }
    ]
  },
  {
    path: "/views/administracion",
    component: Layout2,
    children: [
      {
        path: "facturas-clientes",
        name: "Facturas a clientes",
        component: () => import("@/views/Administracion/FacturasClientes")
      }
    ]
  }
];
